import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { Link } from "gatsby";
import CurveEditor from "../../../components/curve-editor/editor";
export const _frontmatter = {
  "path": "/curve-editor-demo",
  "date": "2021-09-07T00:00:00.000Z",
  "title": "Curve Editor Demo"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Curve Editor Demo`}</h1>
    <p>{`13 years later and I decided to port my `}<inlineCode parentName="p">{`WinForms`}</inlineCode>{` desktop application that was
written in `}<inlineCode parentName="p">{`C++`}</inlineCode>{` to `}<inlineCode parentName="p">{`Typescript`}</inlineCode>{`. The ported demo is below and is rendered using
`}<inlineCode parentName="p">{`SVG`}</inlineCode>{`. Check out my `}<Link to="/projects/curve-editor" mdxType="Link">{`curve editor
project`}</Link>{` to see screenshots and a video of how the curve editor looked as
a `}<inlineCode parentName="p">{`WinForms`}</inlineCode>{` application.`}</p>
    <h2>{`Future plans`}</h2>
    <p>{`I am looking to port the newer `}<inlineCode parentName="p">{`Typescript`}</inlineCode>{` code to `}<inlineCode parentName="p">{`Rust`}</inlineCode>{` so it can be compiled
into `}<inlineCode parentName="p">{`WebAssembly`}</inlineCode>{`.`}</p>
    <h2>{`Demo (Work in progress)`}</h2>
    <CurveEditor mdxType="CurveEditor" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      